import React, {useState, useContext} from "react";

import MenuItem from "@material-ui/core/MenuItem";

import {i18n} from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import {Menu} from "@material-ui/core";
import {ReplyMessageContext} from "../../context/ReplyingMessage/ReplyingMessageContext";
import {EditMessageContext} from "../../context/EditingMessage/EditingMessageContext";
import toastError from "../../errors/toastError";
import ForwardMessageModal from "../ForwardMessageModal";

const MessageOptionsMenu = ({message, menuOpen, handleClose, anchorEl}) => {
    const {setReplyingMessage} = useContext(ReplyMessageContext);
    const {setEditingMessage} = useContext(EditMessageContext);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [fowardModalOpen, setForwardModalOpen] = useState(false);

    const handleDeleteMessage = async () => {
        try {
            await api.delete(`/messages/${message.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const hanldeReplyMessage = () => {
        setReplyingMessage(message);
        handleClose();
    };

    const hanldeEditMessage = () => {
        setEditingMessage(message);
        handleClose();
    };

    const handleOpenConfirmationModal = (e) => {
        setConfirmationOpen(true);
        handleClose();
    };

    const handleForwardMessage = (e) => {
        setForwardModalOpen(true);
        handleClose();
    }

    const handleCloseForwardModal = (e) => {
        setForwardModalOpen(false);
    }

    return (
        <>
            <ConfirmationModal
                title={i18n.t("messageOptionsMenu.confirmationModal.title")}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={handleDeleteMessage}
            >
                {i18n.t("messageOptionsMenu.confirmationModal.message")}
            </ConfirmationModal>
            { message.mediaType === "conversation" || message.mediaType === "extendedTextMessage" || message.mediaType === "chat" || message.mediaType === "image" || message.mediaType === "audio" || message.mediaType === "text" || message.mediaType === "video" || message.mediaType === "application" ? (
                <ForwardMessageModal
                    modalOpen={fowardModalOpen}
                    onClose={handleCloseForwardModal}
                    message={message}
                />
            ) : null}
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleClose}
            >
                {message.fromMe && (
                    <MenuItem onClick={handleOpenConfirmationModal}>
                        {i18n.t("messageOptionsMenu.delete")}
                    </MenuItem>
                )}
                {(message.mediaType === "conversation" || message.mediaType === "extendedTextMessage" || message.mediaType === "chat") && message.fromMe && (
                    <MenuItem onClick={hanldeEditMessage}>
                        Editar
                    </MenuItem>
                )}
                {message.mediaType !== "comment" && (
                    <MenuItem onClick={hanldeReplyMessage}>
                        {i18n.t("messageOptionsMenu.reply")}
                    </MenuItem>
                )}
                { message.mediaType === "conversation" || message.mediaType === "extendedTextMessage" || message.mediaType === "chat" || message.mediaType === "image" || message.mediaType === "audio" || message.mediaType === "text" || message.mediaType === "video" || message.mediaType === "application" ? (
                    <MenuItem onClick={handleForwardMessage}>
                        Encaminhar
                    </MenuItem>
                ) : null}

            </Menu>
        </>
    );
};

export default MessageOptionsMenu;
